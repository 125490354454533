@import "../styles/fonts/proximaNova.css";
@import "../styles/fonts/helvetica.css";
@import "../styles/fonts/testsöhneschmal.css";
:root {
  --var-inspira-purple: #6a73a6;
  --var-inspira-purple2: rgb(66, 69, 119);
  --var-inspira--bk-purple: rgba(66, 69, 119, 0.4);
  --inspira-purple100: #cdcee3;
  /* --inspira-purple: #e4e5ed; */
  --inspira-purple: #a5a8c5;
  /* --inspira-purple2: rgb(66, 69, 119); */
  --inspira-purple2: rgb(105, 110, 159);
  --inspira-purple3: #404577;
  --inspira-gray2: #405765;
  --sportsnravel-green: #00d70d;
  --sportsnravel-green-svg: invert(68%) sepia(61%) saturate(6549%)
    hue-rotate(94deg) brightness(111%) contrast(109%);
  --greendeal-inspira-green: #38b709;
  /* https://codepen.io/sosuke/pen/Pjoqqp */
  --voyvio-orange: #f26a4b;
  --voyvio-orange-svg: invert(62%) sepia(42%) saturate(3965%) hue-rotate(327deg)
    brightness(95%) contrast(101%);
  --aterisko-color: #048abf;
  /*Distinctive Brand Colors change color example: 5991b2 */
  --inspira-backgroundcolor: #eef2f6; /*Background*/
  --inspira-brandcolor-txt: #686ea3; /*color primario texto pagina*/
  --inspira-brandcolor-background: #686ea3; /*color primario background pagina*/
  --inspira-accentcolor-txt: #919191; /*color secundario texto pagina*/
  --inspira-accentcolor-background: #919191; /*color secundario background pagina*/
  --inspira-brandcolorbutton: #465664; /*clase Primary color primario boton, busqueda principal, book, etc*/
  --inspira-accentcolorbutton: #919191; /*color secundario boton*/
  /*--inspira-brandcolorfilter: invert(16%) sepia(0%) saturate(2%) hue-rotate(164deg) brightness(97%) contrast(87%)!important; /*color secundario boton*/
  /*Titles and Subtitles*/
  --inspira-txt-title: #333333; /*HeaderColorFinal Titulos principales*/
  --inspira-txt-subtitle: ##3d3d3d; /*ColorSubHead Titulos secundarios*/
  /*Cards*/
  --inspira-txt-cardtitle: #333333; /*HeaderColorFinal titulo de las tarjetas*/
  --inspira-txt-cardsubtitle: #4d4d4d; /*SubheadFinal titulo de las subtarjetas*/
  --inspira-txt-carddates: #575757; /*ColorSubs2 color de las fechas*/
  --inspira-txt-cardprice: #333333; /*color de las precios*/
  --inspira-bk-modal: rgba(0, 0, 0, 0.7); /*color de las precios*/

  /** Colors base theme **/
  --inspira-subhead-final: #4d4d4d;
  --inspira-headercolor-final: #333333;
  --inspira-primary: #465664;
  --inspira-secondary1: #686ea3;
  --inspira-secondary2: #424577;
  --inspira-tertiary1: #ff9a7b;
  --inspira-tertiary2: #ffaf57;
  --inspira-quaternary1: #08b554;
  --inspira-quaternary2: #45e68b;
  --inspira-backgroundcolor: #eef2f6;
  --inspira-stroke-input: #f6f8fa;
  --inspira-botonfilters: #a4a8c8;
  --inspira-stroke-filters: #e5e5e5;
  --inspira-amenities: #08b554;
  --inspira-points-option-b: #0b81d1;
  --inspira-points-option-a: #ea732d;
  --inspira-on-button: #a4a8c8;
  --inspira-lines: #adadad;
  --inspira-option-off: #bfc0d1;
  --inspira-icon-colors: #363636;
  --inspira-red-selected: #da364f;
  --inspira-lineas-light: #c3c3c3;
  --inspira-body-text: #383838;
  --inspira-rating2: #d0d2e2;
  --inspira-available-dates-bar: #8085b2;
}
.brandcolorfilter {
  filter: invert(48%) sepia(12%) saturate(1630%) hue-rotate(196deg)
    brightness(86%) contrast(82%) !important;
}
.accentcolorfilter {
  filter: invert(59%) sepia(0%) saturate(0%) hue-rotate(297deg) brightness(97%)
    contrast(93%) !important;
}
html,
body {
  padding: 0;
  margin: 0;
  /* overflow: hidden;
  overflow-y: auto; 
  */
  font-family: "Overpass", "Poppins", "ProximaNova", "Helvetica",
    "TestSöhneSchmal", "Cabin", sans-serif, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.moreFiltersBDClass {
  top: 209px !important;
  background-color: #c4c3d4 !important;
}

.moreFiltersCarsClass {
  top: 286px !important;
  background-color: #c4c3d4 !important;
}

.modal-cancelation-backdrop {
  top: 155px !important;
  background-color: #c4c3d4 !important;
}

input[type="range"] {
  --minRangePercent: 0%;
  --maxRangePercent: 50%;
  height: 0.4rem;
}

input[type="range"]:invalid {
  box-shadow: none;
}

/* input[type="range"]::-webkit-slider-thumb {

} */

.min-max-slider {
  position: relative;
  width: 200px;
  text-align: center;
  margin-bottom: 50px;
}

/* .min-max-slider::focus {
  oultine: none;
} */

input::focus {
  outline: none;
}

.min-max-slider > label {
  display: none;
}

.min-max-slider > input {
  cursor: pointer;
  position: absolute;
}

/* webkit specific styling */
.min-max-slider > input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none !important;
  background: transparent;
}

.min-max-slider > input.min {
  background-image: linear-gradient(
    to right,
    silver 0%,
    silver var(--minRangePercent),
    #ea732d var(--minRangePercent),
    #ea732d 100%
  );
}

.min-max-slider > input.max {
  background-image: linear-gradient(
    to right,
    #ea732d 0%,
    #ea732d var(--maxRangePercent),
    silver var(--maxRangePercent),
    silver 100%
  );
}

.min-max-slider > input::focus {
  outline: none;
}

.min-max-slider > input.max::-moz-range-progress {
  background: #ea732d !important;
  border-radius: 4px;
}

.min-max-slider > input.min::-moz-range-progress {
  height: 0.6em;
  background: silver;
  border-radius: 4px;
}

/* input[type="range"]::-webkit-slider-thumb,
input[type="range"]::-moz-range-thumb {
  
} */

.min-max-slider > input::-webkit-slider-runnable-track {
  cursor: grabbing;
}

.scrollModify::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scrollModify::-webkit-scrollbar:vertical {
  width: 10px;
}

.scrollModify::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

.scrollModify::-webkit-scrollbar:horizontal {
  height: 5px;
}

.scrollModify::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border-radius: 0px;
  border: 2px solid #f1f2f3;
}

.scrollModify::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Scroll de My Trips */

.my-trip-list-scroll {
  min-height: 200px;
  overflow: auto;
  cursor: pointer;
}

/* width */
.my-trip-list-scroll::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.my-trip-list-scroll::-webkit-scrollbar-track {
  background: rgb(66, 69, 112, 30%);
  border-radius: 5px;
}

/* Handle */
.my-trip-list-scroll::-webkit-scrollbar-thumb {
  background: #686ea3;
  border-radius: 5px;
}

/* Handle on hover */
.my-trip-list-scroll::-webkit-scrollbar-thumb:hover {
  background: #404378;
}

.brincar {
  z-index: 1;
  animation: saltar 0.4s infinite alternate-reverse;
  animation-timing-function: ease-in;
  transform: translate(0);
}

@keyframes saltar {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 10px solid white;
  margin-left: 1.4rem;
  margin-top: 0rem;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 13px solid white;
  margin-left: 1.4rem;

  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.triangle-down-HotelInfo {
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 12px solid white;
  margin-left: 1.4rem;

  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.country-code-list {
  overflow-y: auto;
  height: 200px;
}

/* width */
.country-code-list::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */

.country-code-list::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 5px !important;
}

/* Handle */

.country-code-list::-webkit-scrollbar-thumb {
  background: #686ea3 !important;
  border-radius: 5px !important;
}

/* Handle on hover */

.country-code-list::-webkit-scrollbar-thumb:hover {
  background: #424570 !important;
}

/*Animacion de tarjetas para wallet*/

.animationWallet {
  animation-name: traslationCard;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes traslationCard {
  from {
    transform: translate(60px, -40px);
  }

  to {
    transform: translate(30px, -20px);
    /*0px*/
    z-index: 999;
  }
}

/* Animacion tarjetas wallet agrupadas */
.cardInitial {
  width: 200px;
  height: 130px;
  position: absolute;
  z-index: 3;
  /* background-image: linear-gradient(to bottom, #ffaf57, #ffa960, #ffa369, #ff9e72, #ff9a7b); */
  border-radius: 10px;
  left: 1.6rem;
}

.cardInitial img {
  border-radius: 10px;
}

.animateInitial {
  animation-name: AnimationInit1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.card1 {
  width: 200px;
  height: 130px;
  position: absolute;
  z-index: 3;
  /* background-image: linear-gradient(to bottom, #ffaf57, #ffa960, #ffa369, #ff9e72, #ff9a7b); */
  border-radius: 10px;
}

.card1 img {
  border-radius: 10px;
}

.animate1 {
  animation-name: AnimationInit1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.card2 {
  width: 200px;
  height: 150px;
  position: absolute;
  transform: translate(30px, -20px);
  z-index: 2;
  /* background-color: red; */
}

.animate2 {
  animation-name: AnimationInit2;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.card3 {
  width: 200px;

  height: 150px;
  position: absolute;
  transform: translate(60px, -40px);
  z-index: 1;
  /*  background-color: green; */
}

.animate3 {
  animation-name: AnimationInit3;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes AnimationInit3 {
  from {
    transform: translate(50px, -30px);
  }

  to {
    transform: translate(60px, -40px);
    z-index: 9;
  }
}

@keyframes AnimationInit1 {
  from {
    transform: translate(30px, -20px);
  }

  to {
    transform: translate(-0px, 0px);
    z-index: 999;
  }
}

@keyframes AnimationInit2 {
  from {
    transform: translate(60px, -40px);
  }

  to {
    transform: translate(30px, -20px);
    z-index: 99;
  }
}

/* Modal Weeek Tabs */
/* Background Modal */
.offcanvas-backdrop.show {
  background-color: darkblue;
  /*height: 77vh !important;*/
  height: 100vh;
  /*margin-top: 27vh !important*/
}

.moreFiltersBDClass2 {
  top: 17rem !important;
  background-color: #c4c3d4 !important;
}

/* LOADING ANIMATION */

.vt-loader {
  border: 5px solid #f3f3f3 !important;
  border-radius: 50%;
  border-top: 5px solid #3498db !important;
  width: 25px;
  height: 25px;
  position: absolute;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  top: 0.6rem;
  left: 5.8rem;
  right: 50%;
}

.vt-loaderUserDetailsVoucher {
  border: 5px solid #f3f3f3 !important;
  border-radius: 50%;
  border-top: 5px solid #3498db !important;
  width: 25px;
  height: 25px;
  position: absolute;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  top: 0rem;
  left: 0em;
  right: 50%;
}

.vt-loaderUserDetailsCancellation {
  border: 5px solid #f3f3f3 !important;
  border-radius: 50%;
  border-top: 5px solid #3498db !important;
  width: 25px;
  height: 25px;
  position: absolute;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  top: 0rem;
  left: 0em;
  margin-left: 40%;
  margin-top: 0.5rem;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* FLIGHTS, PRINT AND DOWNLOAD BTN ANIMATION */
.vt-loaderPrintDownloader {
  border: 5px solid #f3f3f3 !important;
  border-radius: 50%;
  border-top: 5px solid #3498db !important;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

/* Agents Scroll */

.agents-scroll {
  min-height: 5rem;
  overflow: auto;
}

/* width */
.agents-scroll::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.agents-scroll::-webkit-scrollbar-track {
  background: rgb(66, 69, 112, 30%);
  border-radius: 5px;
}

/* Handle */
.agents-scroll::-webkit-scrollbar-thumb {
  background: #686ea3;
  border-radius: 5px;
}

/* Handle on hover */
.agents-scroll::-webkit-scrollbar-thumb:hover {
  background: #404378;
}

/******HOTELS****/

.hotel {
  /* background-color: #bfcdf9 !important; */
  background-color: #686ea3;
}

.inn {
  /* background-color: #af7ac5 !important; */
  background-color: #686ea3;
}

.aparthotel {
  /* background-color: pink !important; */
  background-color: #686ea3;
}

.capsulehotel {
  /* background-color: #148f77 !important; */
  background-color: #686ea3;
}

.resort {
  /* background-color: #6a73a6 !important; */
  background-color: #686ea3;
}

.motel {
  /* background-color: #27cdf2 !important; */
  background-color: #686ea3;
}

.bedandbreakfast {
  /* background-color: #7fb3d5 !important; */
  background-color: #686ea3;
}

.all-inclusiveproperty {
  /* background-color: #f7dc6f !important; */
  background-color: #686ea3;
}

.hostel {
  /* background-color: #73c6b6 !important; */
  background-color: #686ea3;
}

.pension {
  /* background-color: pink !important; */
  background-color: #686ea3;
}

.pousada-portugal {
  /* background-color: #2874a6 !important; */
  background-color: #686ea3;
}

.pousada-brazil {
  /* background-color: pink !important; */
  background-color: #686ea3;
}

/******CONDOS****/

.condo {
  /* background-color: #7dcea0 !important; */
  background-color: #00aeef !important;
}

.apartment {
  /* background-color: #27cdf2 !important; */
  background-color: #00aeef !important;
}

.condominiumresort {
  /* background-color: #00aeef !important; */
  background-color: #00aeef !important;
}

/******RESIDENCES****/
.residence {
  /* background-color: #d98880 !important; */
  background-color: #0ade67 !important;
}

.privatevacationhome {
  /* background-color: #0dd954 !important; */
  background-color: #0ade67 !important;
}

.townhouse {
  /* background-color: #148f77 !important; */
  background-color: #0ade67 !important;
}

.lodge {
  background-color: pink !important;
  /* background-color: #0ade67 !important; */
}

.guesthouse {
  background-color: #76d7c4 !important;
  /* background-color: #0ade67 !important; */
}

.countryhouse {
  /* background-color: #117864 !important; */
  background-color: #0ade67 !important;
}

.cabin {
  /* background-color: #edbb99 !important; */
  background-color: #0ade67 !important;
}

.chalet {
  /* background-color: #ccd1d1 !important; */
  background-color: #0ade67 !important;
}

.cottage {
  /* background-color: #2471a3 !important; */
  background-color: #0ade67 !important;
}

.ranch {
  /* background-color: #f4d03f !important; */
  background-color: #0ade67 !important;
}

.villa {
  /* background-color: #dc7633 !important; */
  background-color: #0ade67 !important;
}

/******OTHER****/
.castle {
  /* background-color: pink !important; */
  background-color: #ffaf57 !important;
}

.palace {
  /* background-color: #148f77 !important; */
  background-color: #ffaf57 !important;
}

.houseboat {
  /* background-color: #e74c3c !important; */
  background-color: #ffaf57 !important;
}

.ryokan {
  /* background-color: #d2b4de !important; */
  background-color: #ffaf57 !important;
}

.treehouseproperty {
  /* background-color: #f5cba7 !important; */
  background-color: #ffaf57 !important;
}

.riad {
  /* background-color: #ffffff !important; */
  background-color: #ffaf57 !important;
}

.safari-tentalow {
  /* background-color: pink !important; */
  background-color: #ffaf57 !important;
}

.agritourismproperty {
  /* background-color: #3498db !important; */
  background-color: #ffaf57 !important;
}

.holidaypark {
  /* background-color: pink !important; */
  background-color: #ffaf57 !important;
}

.campsite {
  background-color: #d68910 !important;
}

.hostal {
  background-color: #5d6d7e !important;
}

.cruise {
  background-color: #3498db !important;
}

.inspiraweeks {
  background-color: #d93b58 !important;
}

.hotweek {
  background-color: #d94e67 !important;
}

.entirehouse {
  background-color: #f27830 !important;
}

.undefinedclassproperty {
  background-color: dodgerblue !important;
}

.searchfilterbtns {
  text-align: center;
}

div#responsive-navbar-nav .justify-content-end.flex-grow-1.pe-3.navbar-nav {
  padding-right: 4rem !important;
}

#responsive-navbar-nav {
  flex-basis: 110%;
}

/* Suggested Flight */

.suggestedScroll {
  overflow: auto;
}

/* width */
.suggestedScroll::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.suggestedScroll::-webkit-scrollbar-track {
  background: rgb(66, 69, 112, 30%);
  border-radius: 5px;
}

/* Handle */
.suggestedScroll::-webkit-scrollbar-thumb {
  background: #686ea3;
  border-radius: 5px;
}

/* Handle on hover */
.suggestedScroll::-webkit-scrollbar-thumb:hover {
  background: #404378;
}

@media (min-width: 1200px) {
  #responsive-navbar-nav {
    flex-basis: 100%;
  }
}

.price-properties {
  font-size: 20px;
}

.price-properties-total {
  font-size: 12px !important;
}

.resort-price-properties {
  font-size: 16px;
}

.resort-properties-total {
  font-size: 12px;
}

/* LOADING FOR BOOKING HISTORY AGENTS */

.vt-loader-booking-history {
  border: 3px solid #f3f3f3 !important;
  border-radius: 50%;
  border-top: 5px solid #3498db !important;
  width: 20px;
  height: 20px;
  -webkit-animation: spinBooking 2s linear infinite;
  animation: spinBooking 2s linear infinite;
  margin-left: 0.5rem;
}

@-webkit-keyframes spinBooking {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinBooking {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.vt-loader-bookHistory-print-emailTimeLine {
  border: 5px solid #f3f3f3 !important;
  border-radius: 50%;
  border-top: 5px solid #3498db !important;
  width: 23px;
  height: 23px;
  -webkit-animation: spinPrintTimeLine 2s linear infinite;
  animation: spinPrintTimeLine 2s linear infinite;
}

@-webkit-keyframes spinPrintTimeLine {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinPrintTimeLine {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* LOADING FOR BOOKING HISTORY AGENTS PRINT AND MAIL*/

.vt-loader-bookHistory-print-email {
  border: 5px solid #f3f3f3 !important;
  border-radius: 50%;
  border-top: 5px solid #3498db !important;
  width: 23px;
  height: 23px;
  -webkit-animation: spinBookingPrintEmail 2s linear infinite;
  animation: spinBookingPrintEmail 2s linear infinite;
  margin-left: 0.5rem;
}

@-webkit-keyframes spinBookingPrintEmail {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinBookingPrintEmail {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* LOADING FOR BOOKING HISTORY AGENTS */

.vt-loader-booking-history {
  border: 3px solid #f3f3f3 !important;
  border-radius: 50%;
  border-top: 5px solid #3498db !important;
  width: 20px;
  height: 20px;
  -webkit-animation: spinBooking 2s linear infinite;
  animation: spinBooking 2s linear infinite;
  margin-left: 0.5rem;
}

@-webkit-keyframes spinBooking {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinBooking {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* LOADING FOR BOOKING HISTORY AGENTS PRINT AND MAIL*/

.vt-loader-bookHistory-print-email {
  border: 5px solid #f3f3f3 !important;
  border-radius: 50%;
  border-top: 5px solid #3498db !important;
  width: 23px;
  height: 23px;
  -webkit-animation: spinBookingPrintEmail 2s linear infinite;
  animation: spinBookingPrintEmail 2s linear infinite;
  margin-left: 0.5rem;
}

@-webkit-keyframes spinBookingPrintEmail {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinBookingPrintEmail {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* LOADING FOR BOOKING HISTORY AGENTS */

.vt-loader-booking-history {
  border: 3px solid #f3f3f3 !important;
  border-radius: 50%;
  border-top: 5px solid #3498db !important;
  width: 20px;
  height: 20px;
  -webkit-animation: spinBooking 2s linear infinite;
  animation: spinBooking 2s linear infinite;
  margin-left: 0.5rem;
}

@-webkit-keyframes spinBooking {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinBooking {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* LOADING FOR BOOKING HISTORY AGENTS PRINT AND MAIL*/

.vt-loader-bookHistory-print-email {
  border: 5px solid #f3f3f3 !important;
  border-radius: 50%;
  border-top: 5px solid #3498db !important;
  width: 23px;
  height: 23px;
  -webkit-animation: spinBookingPrintEmail 2s linear infinite;
  animation: spinBookingPrintEmail 2s linear infinite;
  margin-left: 0.5rem;
}

@-webkit-keyframes spinBookingPrintEmail {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinBookingPrintEmail {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* LOADING FOR BOOKING HISTORY AGENTS */

.vt-loader-booking-history {
  border: 3px solid #f3f3f3 !important;
  border-radius: 50%;
  border-top: 5px solid #3498db !important;
  width: 20px;
  height: 20px;
  -webkit-animation: spinBooking 2s linear infinite;
  animation: spinBooking 2s linear infinite;
  margin-left: 0.5rem;
}

@-webkit-keyframes spinBooking {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinBooking {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* LOADING FOR BOOKING HISTORY AGENTS PRINT AND MAIL*/

.vt-loader-bookHistory-print-email {
  border: 5px solid #f3f3f3 !important;
  border-radius: 50%;
  border-top: 5px solid #3498db !important;
  width: 23px;
  height: 23px;
  -webkit-animation: spinBookingPrintEmail 2s linear infinite;
  animation: spinBookingPrintEmail 2s linear infinite;
  margin-left: 0.5rem;
}

@-webkit-keyframes spinBookingPrintEmail {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinBookingPrintEmail {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.resort-form-check-input {
  width: 15rem;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border-color: transparent;
}

.form-check .resort-form-check-input {
  float: left;
  margin-left: -1.5em;
}

.resort-form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  background-color: #fff;
  border-color: #fff;
}

.resort-form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.resort-form-check-input2:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='rgba(106,110,166,255)' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.resort-form-check-input:checked[type="checkbox"] {
  background-color: #ebeef2;
  border: 2px solid rgba(0, 0, 0, 0.25);
}

.plan-form-check:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='rgba(106,110,166,255)' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.checkmarkoutclass {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #6a73a6;
  stroke-miterlimit: 10;
  stroke-dashoffset: 0;
  margin: 10% auto;
}

.my-section-collapse {
  /* background-color: white; */
  transition: height 2s ease;
  height: 0;
  overflow: hidden;
}

.my-section-collapse.show {
  height: 100%;
}

.my-sectiontop-collapse {
  background-color: white;
  transition: height 2s ease;
  height: 0;
  overflow: hidden;
}

.my-sectiontop-collapse.show {
  height: 100%;
}

.custLink {
  border: transparent;
  background-color: transparent;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: left;
  color: #424577;
  text-decoration: underline;
}

header {
  padding: 1rem 0;
}

.main-content {
  margin-top: 0rem;
}

.product {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem 0;
  margin: 1rem 0;
}

.product-detail {
  padding: 0 2rem;
}

.product-price {
  font-weight: bold;
  font-size: 140%;
}

.slider {
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slider.show {
  max-height: 0;
}

.sliderfirst {
  overflow: hidden;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.sliderfirst.show {
  max-height: 0;
}

.activitiesall {
  overflow-y: hidden;
  max-height: 100%;

  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
}

.activitiesall.show {
  max-height: 0;
}

/* Scroll de -MyTimeLine */

.my-Time-Line-scroll {
  overflow: auto;
  /* cursor: pointer; */
}

/* width */
.my-Time-Line-scroll::-webkit-scrollbar {
  width: 100%;
  height: 0px;
}

/* Track */
.my-Time-Line-scroll::-webkit-scrollbar-track {
  background: rgb(66, 69, 112, 30%);
  border-radius: 5px;
}

/* Handle */
.my-Time-Line-scroll::-webkit-scrollbar-thumb {
  background: #686ea3;
  border-radius: 5px;
}

/* Handle on hover */
.my-Time-Line-scroll::-webkit-scrollbar-thumb:hover {
  background: #404378;
}

.vt-loaderUserDetailsVoucherTimeLine {
  border: 5px solid #f3f3f3 !important;
  border-radius: 50%;
  border-top: 5px solid #3498db !important;
  width: 25px;
  height: 25px;
  -webkit-animation: spspinTimelinein 2s linear infinite;
  animation: spinTimeline 2s linear infinite;
}

@-webkit-keyframes spinTimeline {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinTimeline {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Scroll de -Loyalty Program */

.loyalty-program-scroll {
  overflow: auto;

  /* cursor: pointer; */
}

/* width */
.loyalty-program-scroll::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

/* Track */
.loyalty-program-scroll::-webkit-scrollbar-track {
  background: rgb(66, 69, 112, 30%);
  border-radius: 5px;
}

/* Handle */
.loyalty-program-scroll::-webkit-scrollbar-thumb {
  background: #686ea3;
  border-radius: 5px;
}

/* Handle on hover */
.loyalty-program-scroll::-webkit-scrollbar-thumb:hover {
  background: #404378;
}

/* Scroll de FLights AutoSugested */

.flights-auto-suggest-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  cursor: pointer;
}

/* width */
.flights-auto-suggest-scroll::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.flights-auto-suggest-scroll::-webkit-scrollbar-track {
  background: rgb(66, 69, 112, 30%);
  border-radius: 5px;
}

/* Handle */
.flights-auto-suggest-scroll::-webkit-scrollbar-thumb {
  background: #686ea3;
  border-radius: 5px;
}

/* Handle on hover */
.flights-auto-suggest-scroll::-webkit-scrollbar-thumb:hover {
  background: #404378;
}

.two-fields input {
  width: 50% !important;
}

.vt-loaderPaymenthMethods {
  border: 5px solid #f3f3f3 !important;
  border-radius: 50%;
  border-top: 5px solid #3498db !important;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.moreFiltersActivitiesClass {
  background-color: #c4c3d4 !important;
}

.moreFiltersBackdrop {
  background-color: #c4c3d4 !important;
  height: 77vh;
  margin-top: 13.5rem;
  /*23.5vh;*/
}

.vt-loaderPersonalWallet {
  border: 5px solid #f3f3f3 !important;
  border-radius: 50%;
  border-top: 5px solid #fba766 !important;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  position: absolute;
  bottom: -2em;
  right: 0rem;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background-color: #fff;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  font-size: 13px;
}

[data-title] {
  position: relative;
}

[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
  display: flex;
  justify-content: center;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.8rem;
  right: 0rem;
  padding: 4px 4px 4px 8px;
  color: var(--var-inspira-purple);
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background-color: #fff;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  font-size: 13px;
  font-weight: 600;
}

[data-title2] {
  position: relative;
}

.border-red-htl-card {
  border: 1px solid red !important;
}

.jumplink-hotel-details {
  color: #686ea3;
  border-bottom: 5px solid #686ea3;
  padding-bottom: 5px !important;
}

.jumplink-hotel-details-no-selected:hover {
  color: #686ea3;
  border-bottom: 5px solid #686ea3;
  padding-bottom: 5px !important;
}

.subtitleactbold {
  font-weight: 500;
}

.subtitleactboldlist {
  font-weight: 500;
}

/* Scroll de -Flights OtherSort */

.flights-otherSort-scroll {
  overflow: auto;

  /* cursor: pointer; */
}

/* width */
.flights-otherSort-scroll::-webkit-scrollbar {
  width: 8px;
  height: 2px;
}

/* Track */
.flights-otherSort-scroll::-webkit-scrollbar-track {
  background: rgb(66, 69, 112, 30%);
  border-radius: 5px;
}

/* Handle */
.flights-otherSort-scroll::-webkit-scrollbar-thumb {
  background: #686ea3;
  border-radius: 5px;
}

/* Handle on hover */
.flights-otherSort-scroll::-webkit-scrollbar-thumb:hover {
  background: #404378;
}

.totallengthstay {
  color: #000 !important;
}

.occupationlabel {
  color: #000 !important;
}

.labelicon-checkout {
  color: #000 !important;
}

.title-paymentsummary-hotel,
.label-check {
  color: #000 !important;
}

.roomcharges-info {
  color: #696969 !important;
}

.roomcharges-total {
  color: #696969 !important;
}

.roomcharges-subtotalinfo {
  color: #696969 !important;
}

.roomcharges-subtotaltotal {
  color: #696969 !important;
}

.selecttravelerhotel {
  color: #575757 !important;
}

.travelercheckout-hotel {
  color: #000;
}

.youselectedhotel {
  color: #000;
}

.paymentmethods-checkouthotel,
.titlecupon-hotel,
.checkouthotel-link,
.paymentmethodswallet-checkouthotel {
  color: #000 !important;
}
.subtittlecashcodehotel {
  color: #ec8348 !important;
}
.hotel-checkout-btn-save-traveler,
.btnApplyCheckout,
.btn-complete-booking {
  background-color: #000 !important;
  border: #000 !important;
  color: #fff !important;
}

.cashicon {
  background-image: url("/svg/000000/CashIcon.svg") !important;
}

.cuponcode {
  background-image: url("/svg/000000/cuponIcon.svg") !important;
}

.holidayStaysBookBookingDetails,
.paymentSummaryHolidayStaysBook {
  color: #000 !important;
}

/* Scroll de -MyTrips Activities MultiCards */

.activities-multicards-scroll {
  overflow: auto;

  /* cursor: pointer; */
}

/* width */
.activities-multicards-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.activities-multicards-scroll::-webkit-scrollbar-track {
  background: rgb(66, 69, 112, 30%);
  border-radius: 5px;
}

/* Handle */
.activities-multicards-scroll::-webkit-scrollbar-thumb {
  background: #686ea3;
  border-radius: 5px;
}

/* Handle on hover */
.activities-multicards-scroll::-webkit-scrollbar-thumb:hover {
  background: #404378;
}

.destinationmobile:focus {
  /*border-right: none;*/
  box-shadow: none;
}
.destinationmobile:focus ~ .divbackshowoverlay {
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  /*box-shadow:0 0 0 .25rem rgba(13,110,253,.25);*/
  /*box-shadow: 0 .25rem 0 0 rgba(13,110,253,.25);*/
  box-shadow: 0 0.3rem 0 0 rgba(13, 110, 253, 0.25);
}
.inputsearchplaceholder {
  outline: 0;
}
@media screen and (max-width: 992px) {
  .destination,
  .calendardate.adults-childs,
  .divpick-cars {
    color: #636363 !important;
  }
}
@media screen and (max-width: 1024px) {
  .main-suggestions-section .slick-initialized > .slick-list > .slick-track {
    display: flex !important;
  }
  .main-suggestions-section
    .slick-initialized
    > .slick-list
    > .slick-track
    .slick-slide,
  .main-suggestions-section
    .slick-initialized
    > .slick-list
    > .slick-track
    .slick-active {
    max-width: fit-content !important;
    min-width: fit-content !important;
  }
}

@media screen and (max-width: 1023px) {
  .main-suggestions-section .slick-initialized > .slick-list > .slick-track {
    display: flex !important;
    margin-left: 0rem !important;
  }
  .div-next-trip {
    padding-left: 3rem !important;
  }
  .b-arrow {
    display: none !important;
  }
}

@media screen and (max-width: 455px) {
  .main-suggestions-section
    .section-wso
    .slick-initialized
    .slick-list
    .slick-track
    .slick-slide
    .tw-mx-1 {
    width: 95% !important;
  }
}

.airplaneScalation {
  background: transparent;
  animation: airplaneScale 3s infinite;
  animation-delay: default;
  z-index: 1;
}

@keyframes airplaneScale {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

@media screen and (max-width: 1200px) {
  #crisp-chatbox > div > a {
    bottom: 7rem !important;
  }
}

@keyframes pulsoOpacity {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.pulse-opacity {
  animation: pulsoOpacity 4s infinite ease-in-out;
}
.plan-desc-1,
.slash-text,
.renewal-tag {
  display: none !important;
}

.plan-desc-2 .span1 {
  font-size: 1.8rem;
  line-height: 2.25rem;
  font-weight: 700;
}

.plan-desc-2 .span2 {
  font-size: 1rem;
  font-weight: 700;
}
.CarouselCustom .carousel-inner {
  display: flex;
}
.carousel-act-cards .rec-item-wrapper {
  display: flex;
  justify-content: center;
}
.adscardtype {
  background-color: #000000;
}
.cookieexpandend {
  height: 95vh;
  overflow: hidden;
  @media (max-width: 767px) {
    height: 95%;
    overflow: auto;
  }
}
.cookiehide {
  height: 30vh;
  overflow: hidden;
}
@media (max-width: 767px) {
  .cookiehide {
    height: 85vh; /*75%;*/
    bottom: 3.2rem !important;
  }
}

.txtparrafo {
  font-size: 17px;
}
.txttitulo {
  font-size: 17px;
}

/* Aplicar una regla de media query para pantallas con ancho máximo de 380px */
@media (max-width: 380px) {
  .txtparrafo {
    font-size: 14px !important;
  }
  .txttitulo {
    font-size: 14px !important;
  }
  .customizebordercookies {
    padding: 1rem !important;
    padding-right: 2rem !important;
  }
  .customizebbuttoncookies {
    justify-content: center !important;
  }
}
